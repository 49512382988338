<template>
  <base-section
    id="how-to-start"
    class="grey lighten-3"
  >
    <base-section-heading
      color="grey lighten-2"
      :title="$t('startTitle')"
    >
      {{$t('startSubTitle')}}
    </base-section-heading>

    <v-container>
      <v-row justify="center">
        <v-col
          v-for="card in getCards"
          :key="card.title"
          cols="12"
          md="5"
          sm="6"
        >
          <base-info-card
            v-if="card.title"
            v-bind="card"
          />

          <v-card
            v-else-if="card.code"
            elevation="10"
            class="mb-12"
          >
            <base-code>
              <pre>{{card.code}}</pre>
            </base-code>
          </v-card>

          <v-card
            v-else
            elevation="10"
            class="mb-12"
          >
            <base-img
              max-width="100%"
              :src="card.url"
            />
          </v-card>

        </v-col>

      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionHowToStart',

    computed: {
      getCards () {
        return [
          {
            code: '//download OlapDB hbase Coprocessor\n' +
              'wget https://www.olapdb.com/downloads/olap-observer.jar\n' +
              '\n' +
              '//download OlapDB services\n' +
              'wget https://www.olapdb.com/downloads/olap-master.jar\n' +
              'wget https://www.olapdb.com/downloads/olap-builder.jar\n' +
              'wget https://www.olapdb.com/downloads/olap-query.jar\n' +
              '\n' +
              '//start OlapDB services\n' +
              'nohup java -jar olap-master.jar --hbase.zkQuorum=quorumServer &\n' +
              'nohup java -jar olap-builder.jar --hbase.zkQuorum=quorumServer &\n' +
              'nohup java -jar olap-query.jar --hbase.zkQuorum=quorumServer &\n' +
              '\n' +
              '//download OlapDB Web Management Console and start nginx \n' +
              'yum install nginx\n' +
              'wget https://www.olapdb.com/downloads/olap-manager-web.html.tar.gz\n' +
              'tar -zxvf olap-manager-web.html.tar.gz\n' +
              'cp html /usr/share/nginx/ -r\n' +
              'systemctl restart nginx',
          },

          {
            title: this.$t('startReason1Title'),
            // title: 'Download and start OlapDB services',
            subtitle: 'Step 1',
            html: this.$t('startReason1Content'),
            // html:
            //   'OlapDB is based on Hbase 2.x, so you need to install it first if you don\'t have it.<br>' +
            //   '<br>' +
            //   '<li>Download the OlapDB hbase coprocessor and move it to a directory that hbase can access, such as a hdfs directory. You will need to specify the path of this jar file when you initialize the OlapDB data warehouse.</li>' +
            //   '<li>Download and start the OlapDB service include master service, builder service and query service.</li>' +
            //   // '<li>Download and start the OlapDB builder service</li>' +
            //   // '<li>Download and start the OlapDB query service. </li>' +
            //   '<li>Download and start the OlapDB Web Management Console. (The web management console must be deployed on the same server as the master service.)</li>' +
            //   '<br>' +
            //   '<i>Note: <b>quorumServer</b> is the hbase connect address.</i>' +
            //   '<br>' +
            //   '<br>' +
            //   'Open browser and visit http://nginxServer:80, we can open the web management console page now.',
            callout: '01',
          },
          // {
          //   url: 'screenshots/olapdb_init_cluster.png',
          // },
          {
            title: this.$t('startReason2Title'),
            // title: 'Initialize OlapDB Data Warehouse',
            subtitle: 'Step 2',
            text: this.$t('startReason2Content'),
            // text: 'OlapDB will prompt you to initialize the OlapDB data warehouse when you open the web management console for the first time, including setting the cluster owner, unique identification code, compression algorithm, data partition number and HBase coprocessor.',
            callout: '02',
          },

          {
            url: 'screenshots/olapdb_init_cluster.png',
          },

          {
            url: 'screenshots/olapdb_cube_create.png',
          },
          {
            title: this.$t('startReason3Title'),
            // title: 'Create Project and Data Analysis Cube Model',
            subtitle: 'Step 3',
            text: this.$t('startReason3Content'),
            // text: 'OlapDB can manage multiple projects, each project can contains multiple cube data models. We will add a project first, and then create a cube model and set the detail parameters of this model, include dimensions, metrics, fact name, index dimension, data expiration time etc',
            callout: '03',
          },
          {
            title: this.$t('startReason4Title'),
            // title: 'Push Data To OlapDB',
            subtitle: 'Step 4',
            text: this.$t('startReason4Content'),
            callout: '04',
          },
          {
            code: 'String cubeName = ... ;\n' +
              'List<JSONObject> records = .....;\n' +
              '\n' +
              '//combine fact data to a list \n' +
              'List<String> facts = new Vector<String>();\n' +
              'for(JSONObject record:records){\n' +
              '    facts.add(record.toString());\n' +
              '}\n' +
              '\n' +
              '//sumbit data to OlapDB\n' +
              'Olap.submitSegment(cubeName, facts);',
          },
          {
            url: 'screenshots/olapdb_show.png',
          },
          {
            title: this.$t('startReason5Title'),
            // title: 'Integrate BI Tools to show Analysis Result',
            subtitle: 'Step 5',
            text: this.$t('startReason5Content'),
            callout: '05',
          },
        ]
      },

      getButtons () {
        return [
          {
            title: this.$t('deployMoreDetail'),
            href: '/docs/user-manual.html#5-安装部署',
          },
        ]
      },
    },

    data: () => ({
      cards: [
        {
          title: '准备好HBase环境',
          subtitle: 'Everything Is Ready',
          text: 'OlapDB的部署需要使用HBase服务。在部署OlapDB的组件前，您先要准备好HBase环境。在安装部署其他服务前，您需要将OlapDB提供协处理器的jar文件下载复制到HBase能够访问的位置。',
          callout: '01',
        },
        {
          title: '通过镜像启动服务',
          subtitle: 'Start Olap Service',
          text: 'OlapDB提供的单一Docker镜像中集成了管理服务、构建服务、查询服务和Web管理控制台。你可以通过启动镜像直接启动这些服务。',
          callout: '02',
        },
      ],
    }),
  }
</script>
